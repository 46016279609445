import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import "../assets/css/slider-fix.css";

const AutoplaySlider = withAutoplay(AwesomeSlider);

/**
 * SLIDER REPO & DOCS : https://github.com/rcaferati/react-awesome-slider
 */

const Slider = () => {
    const slideImages = [
        "assets/slides/slide_1.jpg",
        "assets/slides/slide_2.jpg",
        "assets/slides/slide_3.jpg",
        "assets/slides/slide_4.jpg",
        "assets/slides/slide_5.jpg",
        "assets/slides/slide_6.jpg",
        "assets/slides/slide_7.jpg",
        "assets/slides/slide_8.jpg",
    ];

    return (
        <div className="bg-slate-200">
            <div className="slide-container w-full">
                <AutoplaySlider
                    play={true}
                    infinite={true}
                    cancelOnInteraction={false}
                    interval={6000}
                >
                    {slideImages.map((slide, index) => (
                        <div key={index} data-src={slide} />
                    ))}
                </AutoplaySlider>
            </div>
        </div>
    );
};

export default Slider;
