import Header from "./sections/Header";
import Slider from "./sections/Slider";
import About from "./sections/About";
import Brands from "./sections/Brands";
import Map from "./sections/Map";

function App() {
    return (
        <div id="container" className="container px-0 md:px-12 lg:px-24">
            <Header />
            <Slider />
            <About />
            <Brands />
            <Map />
        </div>
    );
}

export default App;
