import Brand from "../components/Brand";

const Brands = () => {
    return (
        <div className="bg-slate-800 p-6 md:p-12 lg:p-24 flex flex-wrap justify-center">
            <Brand name="ipekyol" />
            <Brand name="twist" />
            <Brand name="tosato" />
            <Brand name="kipling" />
        </div>
    );
};

export default Brands;
