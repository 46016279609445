import React from "react";

const Map = () => {
    /**
        const GOOGLE_MAPS_API_KEY = "AIzaSyAEX8ur-oGZWUNmZXXn5bIZ10sLqLLaQ1M";
        const MERKEZ = {
            lat: 40.73112412664069,
            lng: 31.60159372572853,
        };
     */

    return (
        <div className="h-[500px] md:h-[800px]">
            <div className="bg-slate-900 p-4 text-white text-center">
                MERKEZ : Tabaklar Mahallesi, Vaysal Sokak,
                <br />
                Ergür İş Hanı, No:5/60, 14100 Merkez/BOLU
            </div>
            {/* <iframe
                title="Ozan Giyim"
                // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5641.795884590847!2d31.596598173105175!3d40.73277864746102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x409d403db206431d%3A0x1f1f9688a499198b!2sOzan%20Giyim!5e0!3m2!1str!2str!4v1670260055170!5m2!1str!2str"
                src={`https://www.google.com/maps/embed?key=${GOOGLE_MAPS_API_KEY}&pb=!1m14!1m12!1m3!1d188.96292693899701!2d31.60154437450642!3d40.73107275182842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2str!4v1671020838671!5m2!1sen!2str`}
                style={{ border: 0, width: "100%", height: "100%" }}
                loading="lazy"
            ></iframe> */}
            <iframe
                title="Ozan Giyim"
                src="https://www.google.com.tr/maps/embed?pb=!4v1671052058018!6m8!1m7!1sRmWXgUZQBq20qWbWwa6-pw!2m2!1d40.73110710660451!2d31.60168888565878!3f282.20430056281987!4f3.058784372493733!5f1.3203209472570765"
                style={{ border: 0, width: "100%", height: "100%" }}
                loading="lazy"
            ></iframe>
        </div>
    );
};

export default Map;
