import Ipekyol from "../assets/brands/ipekyol.jpg";
import Twist from "../assets/brands/twist.jpg";
import Tosato from "../assets/brands/tosato.jpg";
import Kipling from "../assets/brands/kipling.jpg";
const Brand = ({ name }) => {
    return (
        <div className="bg-white rounded-lg shadow-lg h-24 flex justify-center items-center mx-4 my-2 px-2 md:px-16">
            {name === "ipekyol" && (
                <img src={Ipekyol} alt="Ipekyol" className="w-28" />
            )}
            {name === "twist" && (
                <img src={Twist} alt="Twist" className="w-28" />
            )}
            {name === "tosato" && (
                <img src={Tosato} alt="Twist" className="w-28" />
            )}
            {name === "kipling" && (
                <img src={Kipling} alt="Twist" className="w-28" />
            )}
        </div>
    );
};

export default Brand;
