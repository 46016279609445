import BattalTurksever from "../assets/battal-turksever.jpg";
import SamiYaseminTurksever from "../assets/sami-yasemin-turksever.jpg";

const About = () => {
    return (
        <div className="bg-slate-100 py-12 px-8 lg:p-32">
            <div className="flex flex-col items-center justify-center md:flex-row mb-4 md:mb-10">
                <div className="mr-0 mb-10 md:mb-0 md:mr-10">
                    <img
                        alt="Battal Türksever"
                        src={BattalTurksever}
                        className="s w-64"
                    />
                </div>
                <div className="font-bold">
                    <p>
                        Sözlerim insanoğluna
                        <br />
                        Sev insanı gir koluna
                        <br />
                        Bakma rengine soyuna
                        <br />
                        İnsan insanı sevmeli
                    </p>
                    <br />
                    <p>
                        Tüm insanlar benzer sana
                        <br />
                        Sev de saygı duy insana
                        <br />
                        Kırma gönül kıyma cana
                        <br />
                        İnsan insanı sevmeli
                    </p>
                    <br />
                    <p>
                        Türksever der gel sev insanı
                        <br />
                        Sev de sen incitme canı
                        <br />
                        Sana düşman etme onu
                        <br />
                        İnsan insani bilmeli
                    </p>
                    <br />
                </div>
            </div>
            <p className="indent-9">
                Bu dizelerin sahibi merhum Battal TÜRKSEVER. Nasıl da güzel
                söylemiş. Hümanist, insan sevgisi dolu büyüğümüz 1967 yılında
                çıktığı bu yolu bizlere de yol yapmış. Bolu’nun ilk mankenli
                Ozan Tuhafiye’si.
            </p>
            <br />
            <p className="indent-9">
                Çıkış noktası saygı sevgi ve hoşgörü olan bir işletmeyle atılmış
                ticaret hayatına. Yetmişli yıllarda oğlu merhum Sami TÜRKSEVER’e
                devretmiş bu felsefeyle işletmeyi. Sami Bey ve eşi Yasemin Hanım
                da önce birbirlerine sevgi, saygı ve hoşgörüyle başladıkları bu
                serüveni yine bu düsturla devam ettirmişler. Bolu’nun vizyoner,
                sıcak ve dostça kapıları açılmış binlerce müşteriye.
            </p>
            <br />
            <p className="indent-9">
                Yıllar içinde Ozan Giyim adeta bir marka olmuş. Ta ki pandemi
                süreci aralık 2020 yılına kadar. Sami Bey’in vefatı ile eşi
                Yasemin Hanım ve oğulları Ozan-Okan TÜRKSEVER bu yolculuğu
                sürdürmeye devam etmişler.
            </p>
            <img
                src={SamiYaseminTurksever}
                alt="Sami-Yasemin Türksever"
                className="w-[700px] m-auto py-12"
            />
            <p className="indent-9">
                Çıkış noktası insan odaklı olan, bu maneviyatla yoluna devam
                eden <strong>Ozan Giyim</strong> her zamanki gibi kalite, vizyon
                ve titizlikle Bolulularin kalbinde yer almaya devam edecektir.
            </p>
        </div>
    );
};

export default About;
