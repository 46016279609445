import LogoFull from "../assets/logoFull.png";

const Header = () => {
    return (
        <div className="h-24 flex items-center justify-between">
            <div>
                <img
                    alt="Ozan Giyim Logo"
                    src={LogoFull}
                    className="h-16 pl-2 md:pl-0 md:h-20"
                />
            </div>
            <div className="flex flex-col items-end pr-2 md:pr-0">
                <div>
                    <span className="font-bold text-sm">MERKEZ :</span>
                    <a href="tel:+90 374 212 20 91" className="text-sm">
                        +90 374 212 20 91
                    </a>
                </div>
                <div>
                    <span className="font-bold text-sm">14BURDA AVM :</span>
                    <a href="tel:+90 374 274 13 37" className="text-sm">
                        +90 374 274 13 37
                    </a>
                </div>
                <div>
                    <span className="font-bold text-sm">Email :</span>
                    <a href="mailto:bilgi@ozangiyim.com" className="text-sm">
                        bilgi@ozangiyim.com
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Header;
